/*********************************************************************
 * Fonts
 *
 */

@font-face {
 font-family: 'Inconsolata';
 src: url('#{$path-fonts}/inconsolata.eot');
 src: url('#{$path-fonts}/inconsolata.eot?#iefix') format('embedded-opentype'), 
      url('#{$path-fonts}/inconsolata.woff') format('woff'), 
      url('#{$path-fonts}/inconsolata.ttf')  format('truetype'),
      url('#{$path-fonts}/inconsolata.svg#inconsolata') format('svg');
}

@font-face {
 font-family: 'Inconsolata Bold';
 src: url('#{$path-fonts}/inconsolata-bold.eot');
 src: url('#{$path-fonts}/inconsolata-bold.eot?#iefix') format('embedded-opentype'), 
      url('#{$path-fonts}/inconsolata-bold.woff') format('woff'), 
      url('#{$path-fonts}/inconsolata-bold.ttf')  format('truetype'),
      url('#{$path-fonts}/inconsolata-bold.svg#inconsolata-bold') format('svg');
}

@font-face {
  font-family: 'FontAwesome';
  src: url('#{$path-fontawesome}/fontawesome-webfont.eot?v=4.2.0');
  src: url('#{$path-fontawesome}/fontawesome-webfont.eot?#iefix&v=4.2.0') format('embedded-opentype'),
       url('#{$path-fontawesome}/fontawesome-webfont.woff?v=4.2.0') format('woff'),
       url('#{$path-fontawesome}/fontawesome-webfont.ttf?v=4.2.0') format('truetype'),
       url('#{$path-fontawesome}/fontawesome-webfont.svg?v=4.2.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'fontello';
  src: url('#{$path-fonts}/fontello.eot?55505753');
  src: url('#{$path-fonts}/fontello.eot?55505753#iefix') format('embedded-opentype'),
       url('#{$path-fonts}/fontello.woff?55505753') format('woff'),
       url('#{$path-fonts}/fontello.ttf?55505753') format('truetype'),
       url('#{$path-fonts}/fontello.svg?55505753#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-cogs:before {
  content: "\f085"
}

.fa-cog:before {
  content: "\f013";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -15%
}

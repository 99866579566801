/*********************************************************************
 * Susy Grid
 *
 */

// http://susydocs.oddbird.net

//  body { @include container(80em); }
//  nav { @include span(25%); }

$susy: (
  flow: ltr,
  math: fluid,
  output: float,
  gutter-position: after, // default: after
  container: auto, // default: auto
  container-position: center,
  columns: 6, // default: 4
  gutters: .25,
  column-width: false,
  global-box-sizing: content-box,
  last-flow: to,
  debug: (
    image: hide, // default: hide
    color: rgba(#F00, .05), // default: rgba(#66f, .25);
    output: background,
    toggle: top right,
  ),
  use-custom: (
    background-image: true,
    background-options: false,
    box-sizing: true,
    clearfix: false,
    rem: true,
  ),
  role: nest
);

  #container {
//    @include container(1400px);
    @include container(1400px 6 .25 after fluid);
    
    @include breakpoint(767px) {
//        @include span(2 of 10);
    }
  }



  //@media only screen and (max-width: 767px) {
  //  /* mobile layout */
  //
  //  body, td, textarea {
  //    font-size: 100%;
  //  }
  //  body.has-rightbar #content,
  //  body.has-rightbar #rightbar {
  //    float: none;
  //    width: 100%;
  //    padding: 0;
  //  }
  //  form.search {
  //    float: none;
  //    width: 100%;
  //  }
  //  #content {
  //    width: 100%;
  //  }
  //  #rightbar {
  //    border-top: 1px solid #eee;
  //    padding-top: 1em;
  //  }
  //  .align_left, .align_right, .align_center {
  //    display: block;
  //    float: none;
  //    margin: 1em auto;
  //    max-width: 100%;
  //  }
  //
  //}
  //
  //@media only screen and (min-width: 1200px) {
  //  /* extra-wide desktop layout */
  //
  //  body, td, textarea {
  //    font-size: 115%;
  //  }
  //}
/*********************************************************************
 * 1. General HTML tags
 *
 */

* {
  box-sizing: border-box;
}

html {
    overflow-y:scroll;
}
  
body {
  padding: 35px 5% 0 5%;
//  max-width: 1600px;
//  margin: 0 auto;
// font-size: 16px;
}

body, td, input[type=text], textarea {
  font-family: Inconsolata, serif; // 'Gill Sans'

  font-size: 106.25%; /* 17px */
  line-height: 1.5;
  color: #444;
}

img {
  max-width: 100%;
}

h1 {
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 0.15em;
}

h2 {
  font-weight: normal;
}

h3 {
  font-weight: normal;
  border-top: 1px solid $color;
  padding: 0.75em 0 0 0;
  color: $color;
}

p {
//  background-color: rgba(255,0,0,0.25);
  margin: 0.6em 0;
  padding: 0;
}


a:link,
a:visited {
  color: #333;
  text-decoration: none;
  border-bottom: 1px solid #ccc;
}

a:hover,
a:active,
a:focus,
.nav a:hover,
.nav a:active,
.nav a:focus {
  color: $color;
  border-color: $color;
  -webkit-transition: all .35s easeInOut;
     -moz-transition: all .35s easeInOut;
       -o-transition: all .35s easeInOut;
          transition: all .35s easeInOut;
}

blockquote {
  margin-left: 0;
  padding-left: 1.5em;
  padding-right: 2em;
  border-left: 4px solid #ddd;
  font-style: italic;
  color: #777;
}

pre, code {
  background: #eee;
  border: 1px solid #ddd;
}

pre {
  font-size: 14px;
  line-height: 1.4em;
  padding: 1em;
  border-left: 4px solid #ddd;
}
/*********************************************************************
 * Schauraum
 *
 */

body.tpl-schauraum {

  ul.news {
    li {
      margin-top:1.35em;
      margin-bottom:1.35em;
      border-top: 1px solid $color;
      padding-top: .75em;
      clear:both;
      div.text {
        @include span(6 of 6);
        @include susy-breakpoint(479px,6) { @include span(3 of 6); }
        @include susy-breakpoint(1023px,6) { @include span(2 of 6); }
        a {
          font-size: 150%;
          line-height: 1em;
          text-decoration: none;
          border:0;
          color: $color;
        }
      }
      div.image {
        margin-top:1.35em;
        margin-bottom:1.35em;
        @include span(6 of 6);
        @include susy-breakpoint(479px,6) { @include span(3 of 6 last); }
        @include susy-breakpoint(1023px,6) { @include span(2 of 6); }
        a {border:0;}
      }
      div.thumbs {
        margin-top:1.35em;
        margin-bottom:1.35em;
        @include span(6 of 6);
        @include susy-breakpoint(1023px,6) { @include span(2 of 6 last); }
        div {
          background-color: transparent;
          a {border:0;}
        }
      }
    }
  }
}
/*********************************************************************
 * Hover Effect for Object Thumbs
 * via http://ianlunn.github.io/Hover/
 */

///* Bounce In */
//.hvr-bounce-in {
//  display: inline-block;
//  vertical-align: middle;
//  -webkit-transform: translateZ(0);
//  transform: translateZ(0);
//  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
//  -webkit-backface-visibility: hidden;
//  backface-visibility: hidden;
//  -moz-osx-font-smoothing: grayscale;
//  -webkit-transition-duration: 0.5s;
//  transition-duration: 0.5s;
//}
//.hvr-bounce-in:hover, .hvr-bounce-in:focus, .hvr-bounce-in:active {
//  -webkit-transform: scale(1.2);
//  transform: scale(1.2);
//  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
//  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
//}
//
///* Grow Shadow */
//.hvr-grow-shadow {
//  display: inline-block;
//  vertical-align: middle;
//  -webkit-transform: translateZ(0);
//  transform: translateZ(0);
//  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
//  -webkit-backface-visibility: hidden;
//  backface-visibility: hidden;
//  -moz-osx-font-smoothing: grayscale;
//  -webkit-transition-duration: 0.3s;
//  transition-duration: 0.3s;
//  -webkit-transition-property: box-shadow, transform;
//  transition-property: box-shadow, transform;
//}
//.hvr-grow-shadow:hover, .hvr-grow-shadow:focus, .hvr-grow-shadow:active {
//  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
//  -webkit-transform: scale(1.1);
//  transform: scale(1.1);
//}

/* Bounce In */
.aagd-bounce-in {
  img {
    display: inline-block;
    vertical-align: middle;
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    backface-visibility: hidden;
    transition-duration: 0.25s;
  }
}
.aagd-bounce-in:hover, .aagd-bounce-in:focus, .aagd-bounce-in:active {
  img {
    transform: translateZ(30px) rotateX(15deg) rotateY(2deg);
    transition-timing-function: cubic-bezier(.87,-.41,.19,1.44);
  }
}

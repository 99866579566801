/*********************************************************************
 * Impressum
 *
 */

body.tpl-basicpage {

  div#content {
    margin-bottom:1em;
    @include span(6 of 6);
    @include susy-breakpoint(479px,6) { @include pre(1 of 6); @include span(3 of 6); }
    @include susy-breakpoint(1023px,6) { @include pre(0); @include span(3 of 6); }
  }

  div#rightbar {
    margin-bottom:1em;
    @include span(6 of 6);
    @include susy-breakpoint(479px,6) { @include span(3 of 6 last); }
    @include susy-breakpoint(1023px,6) { @include span(3 of 6 last); }
    a {border:0;}
  }

}
/*********************************************************************
 * Kontakt
 *
 */


body.tpl-kontakt {
  
  #map {
    @include span(6 of 6);
    @include susy-breakpoint(767px,6) { @include span(4 of 6 last); }
    @include susy-breakpoint(1023px,6) { @include span(4 of 6 last); }
    margin-bottom:2em;

    position: relative;
    height: 600px;

    #mapborder {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 2px solid #555;
      pointer-events: none;
      filter:progid:DXImageTransform.Microsoft.AlphaImageLoader(src='your_transparent.png', sizingMethod='scale');
      background: none !important;
    }

  }

  #main #leftbar {
    
    @include span(6 of 6);
    @include susy-breakpoint(767px,6) { @include span(2 of 6); }
    @include susy-breakpoint(1023px,6) { @include span(2 of 6); }
    margin-bottom:2em;

    h4 {
      margin: 0;
      padding:0;
    }

    p {
      margin-top: 0;
    }
    
  }

  hr {
    border:none;
    border-top:solid #ccc 1px;
    width: 90%;
    text-align: left;
    margin: 1.2em 0 1em 0;
  }

  body a.nodeco {
    text-decoration: none;
    border: none;
  }

  a.logo {
    border: 0;
  }

  a.logo img {
    margin: 0.25em 0 1em 0;
  }

}

//body.tpl-home {
//
//  div#main h1 { display: none; }
//
//  div.text {
//    h1,h2 {
//      padding-top:0;
//      margin-top:0.25em;
//    }
//  }
//  div.image {
//    margin-top:1em;
//    margin-bottom:1em;
//    a {border:0;}
//  }
//}
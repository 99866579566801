/*********************************************************************
 * Objekt
 *
 */

body.tpl-objekt {

  div.text {
    h1,h2 {
      padding-top:0;
      margin-top:0.25em;
    }
    @include span(6 of 6);
    @include susy-breakpoint(479px,6) { @include span(5 of 6); }
    @include susy-breakpoint(1023px,6) { @include pre(0); @include span(2 of 6); }
 }

  div.gallery {
    margin-top:1em;
    margin-bottom:1em;
    @include span(6 of 6);
    @include susy-breakpoint(479px,6) { @include span(6 of 6); }
    @include susy-breakpoint(1023px,6) { @include span(4 of 6 last); }
    a {border:0;}
  }

}
/*********************************************************************
 * Objekte
 *
 */

body.tpl-objekte {
  #stage {
    @include span(2 of 2);
    @include susy-breakpoint(767px,6) { @include span(4 of 6 ); @include pre(1 of 6); }
  }
  
  // http://tympanus.net/codrops/2013/02/06/interactive-infographic-with-svg-and-css-animations/
  
  // Setting transform-origins
  
  #jrl #logo-jrl {
    transform-origin: 165px 165px;
  }
  #divase #logo-divase {
    transform-origin: 569px 250px;
  }
  #kroher #logo-kroher { // 329.45×118.5
    transform-origin: 569px 80px;
  }
  #gaeste #logo-gaeste { // 205.5×38.8333
    transform-origin: 419px 400px;
  }
  
  // Apply some initial transformations

  #logo-jrl,
  #logo-divase,
  #logo-kroher,
  #logo-gaeste {
    transform: scale(1, 1) rotate(0deg);
    fill: #444;
  }
  
  #jrl,
  #divase,
  #kroher,
  #gaeste {
  }
  
  #jrl #bg-jrl,
  #divase #bg-divase,
  #kroher #bg-kroher,
  #gaeste #bg-gaeste {
    fill: #202020;
    fill-opacity:0;
    x:0;
    y:0;
  }

  // Add in the :hover styles
  
  #jrl:hover #logo-jrl,
  #divase:hover #logo-divase,
  #kroher:hover #logo-kroher,
  #gaeste:hover #logo-gaeste {
    fill: $color;
  }


  #jrl:hover #bg-jrl,
  #divase:hover #bg-divase,
  #kroher:hover #bg-kroher,
  #gaeste:hover #bg-gaeste {
    fill: $color;
    fill-opacity:0;
  }

  #kroher:hover #logo-kroher,
  #gaeste:hover #logo-gaeste {
    transform: scale(1.2, 1.2) rotate(-1deg);
  }

  #divase:hover #logo-divase {
    transform: scale(1.2, 1.2) rotate(1deg) translatex(-20px); 
  }

  #jrl:hover #logo-jrl {
    transform: scale(1.2, 1.2) rotate(4deg);
  }



  // Apply transitions
  
  #jrl,
  #divase,
  #kroher,
  #gaeste,
  #jrl #logo-jrl,
  #divase #logo-divase,
  #kroher #logo-kroher,
  #gaeste #logo-gaeste,
  #jrl #bg-jrl,
  #divase #bg-divase,
  #kroher #bg-kroher,
  #gaeste #bg-gaeste {
  //    transition: transform 0.25s ease-in-out, fill 0.25s ease-in-out;
  // http://matthewlein.com/ceaser/
    -webkit-transition: all 300ms cubic-bezier(0.8,    0, 0.5, 1); /* older webkit */
    -webkit-transition: all 300ms cubic-bezier(0.8, -0.5, 0.5, 1.5);
       -moz-transition: all 300ms cubic-bezier(0.8, -0.5, 0.5, 1.5);
         -o-transition: all 300ms cubic-bezier(0.8, -0.5, 0.5, 1.5);
            transition: all 300ms cubic-bezier(0.8, -0.5, 0.5, 1.5); /* easeInOutBack */

    -webkit-transition-timing-function: cubic-bezier(0.8,    0, 0.5, 1); /* older webkit */
    -webkit-transition-timing-function: cubic-bezier(0.8, -0.5, 0.5, 1.5);
       -moz-transition-timing-function: cubic-bezier(0.8, -0.5, 0.5, 1.5);
         -o-transition-timing-function: cubic-bezier(0.8, -0.5, 0.5, 1.5);
            transition-timing-function: cubic-bezier(0.8, -0.5, 0.5, 1.5); /* easeInOutBack */
            
  }
  
  // The Intro Animation
  
//  @keyframes left-brace-intro {
//    0% {
//      transform: translateX(220px);
//      opacity: 0;
//    }
//    50% {
//      opacity: 1;
//      transform: translateX(220px);
//    }
//    100% {
//      transform: translateX(0px);
//    }
//  }
//  @keyframes right-brace-intro {
//    0% {
//      transform: translateX(-220px);
//      opacity: 0;
//    }
//    50% {
//      opacity: 1;
//      transform: translateX(-220px);
//    }
//    100% {
//      transform: translateX(0px);
//    }
//  }
//  @keyframes fade-in {
//    0% {
//      opacity: 0;
//    }
//    100% {
//      opacity: 1;
//    }
//  }
//  @keyframes grow-y {
//    0% {
//      transform: scaleY(0);
//    }
//    100% {
//      transform: scaleY(1);
//    }
//  }
//  @keyframes grow-x {
//    0% {
//      transform: scaleX(0);
//    }
//    100% {
//      transform: scaleX(1);
//    }
//  }
//  @keyframes grow {
//    0% {
//      transform: scale(0, 0);
//    }
//    100% {
//      transform: scale(1, 1);
//    }
//  }
  
  // Creating an Animated Intro Sequence
  
//  .svgLoaded #logo {
//    animation: fade-in 0.5s ease-in-out;
//  }
//  .svgLoaded #quote-text {
//    animation: fade-in 0.5s ease-in-out 0.75s;
//    animation-fill-mode: backwards;
//  }
//  .svgLoaded #quote-left-brace {
//    animation: left-brace-intro 1s ease-in-out 0.25s;
//    animation-fill-mode: backwards;
//  }
//  .svgLoaded #quote-right-brace {
//    animation: right-brace-intro 1s ease-in-out 0.25s;
//    animation-fill-mode: backwards;
//  }
//  .svgLoaded #background {
//    animation: grow-y 0.5s ease-in-out 1.25s;
//    transform-origin: 512px 300px;
//    animation-fill-mode: backwards;
//  }
//  .svgLoaded #background > g {
//    animation: grow-x 0.25s ease-in-out 1.75s;
//    animation-fill-mode: backwards;
//  }
//  .svgLoaded #background > g:last-of-type {
//    transform-origin: 458px 877px;
//  }
//  .svgLoaded #background > g:first-of-type {
//    transform-origin: 563px 877px;
//  }
//  .svgLoaded #coffee, .svgLoaded #design, .svgLoaded #build, .svgLoaded #complain, .svgLoaded #beer {
//    animation: grow 0.25s ease-in-out;
//    animation-fill-mode: backwards;
//  }
//  .svgLoaded #coffee {
//    animation-delay: 2s;
//  }
//  .svgLoaded #design {
//    animation-delay: 2.25s;
//  }
//  .svgLoaded #build {
//    animation-delay: 2.5s;
//  }
//  .svgLoaded #complain {
//    animation-delay: 2.75s;
//  }
//  .svgLoaded #beer {
//    animation-delay: 3s;
//  }
  
}
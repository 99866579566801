/* Navigation
-------------------------------------------------- */

//header { position:relative; }

ul.topnav {
  margin-top: 2em;
  float: right;
  clear:right;
}

.topnav, .topnav li {
  list-style: none;
  padding: 0;
  margin: 0;
}
  .topnav li {
    float: left;
    margin-right: 1em;
    margin-bottom: 1em;
    &:last-child{ margin-right: 0;};
  }
  .topnav a, .topnav span.nolink a {
    padding: 0.25em 0.5em;
    text-decoration: none;
    display: block;
    background: #eee;
    color: #333;
    border: 1px solid #eee;
  }
  .topnav a:hover {
    background: #ddd;
    border-color: #ddd;
  }
  .topnav span.nolink a:hover {
    background: #eee;
    border-color: #eee;
  }
  .topnav li.current a {
    background: #ddd;
    border-color: #ddd;
    &:hover {
      background: $color;
      border-color: $color;
      color: white;
    }
  }
  .topnav li.current span.nolink a {
    background: #ddd;
    border-color: #ddd;
    &:hover {
      background: #ddd;
      border-color: #ddd;
      color: #222;
    }
  }

  .topnav li.edit a {
    background: none;
    &:hover {
      color: $color;
    }
  }

 // Facebook Link

  .topnav li.fb a {
    background: none;
    &:hover {
      border-color: #4A89DC;
    }
  }

  .fb a:link i,
  .fb a:visited i{
    color: #333;
  }

  .fb a:hover i,
  .fb a:active i,
  .fb a:focus i {
    color: #4A89DC;
  }



.topnav {
  display: inline-block; // aagd

  a {
    display: block;
    color: #222;
    &:hover {
      background:  $color;
      border-color: $color;
      color: white;
      text-decoration: none;
    }
  }


  .active {
    color: $color;
  }

  > li {
    position: relative;
    float: left;
    &:hover .submenu {
      display: block;
    }
  }

  li {
    list-style: none;
    ul.submenu {
      position: absolute;
      z-index: 2;
      display: none;
      left: 0;
      margin-left: 0;
      padding-left: 0;

      li {
        margin-top: 1px;
        margin-bottom: 0;
        margin-left: 0;
        padding-left: 0;
        list-style: none;
      }
      a {
        color: white;
        background: #444;
        border-color: #444;
        white-space: nowrap;
      }
      a:hover {
        background:  $color;
        border-color: $color;
      }
    }

    // Adding a Submenu Indicator

    > a:after, > span.nolink a:after {
      color: #222;
      content: ' ▾';
    }

    > a:hover:after {
      color: white;
      content: ' ▾';
    }

    > a:only-child:after {
      content: '';
    }
  }
}
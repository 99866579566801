/*********************************************************************
 * Person
 *
 */

body.tpl-person-vonform {
  div#main {
    div#content {
      div.body {
        margin-bottom:3em;
        @include span(3 of 3);
        @include susy-breakpoint(479px,6) { @include span(3 of 3); }
        @include susy-breakpoint(1023px,6) { @include span(1 of 3); }
      }
      div.works {
        @include span(6 of 6);
        @include susy-breakpoint(479px,6) { @include span(3 of 3); }
        @include susy-breakpoint(1023px,6) { @include span(2 of 3 last); }

        li {
          @include gallery(2 of 4);
          @include susy-breakpoint(479px,6) { @include gallery(1 of 4); }
          @include susy-breakpoint(1023px,6) { @include gallery(1 of 4); }
          text-align: center;
          display: inline-block;
          vertical-align: top;
          margin:0 0 1em 0;
          padding:0;

          span.title {
            line-height: 1em;
            margin:0 0 10px 0;
            padding:0;
            display: inline-block;

          }


          a {
            border:0;
            img {
              border-radius: 50%;
              width:100%;
              max-width:195px;
              height:auto;
              max-height:195px;
              background-color: rgba(0,0,0,0.05);
              color: rgba(0,0,0,0.2);;
              display: inline-block;
              margin-bottom: 10px;
              box-shadow: 0px 30px 45px -15px rgba(0,0,0,0.25);
              &:hover {
                box-shadow: 0px 30px 65px -15px rgba(0,0,0,0.4);
              }
            }
          }
        }
      }
      div.gallery-page {
        margin-top:1em;
        margin-bottom:1em;
        div.text {
        }
        div.gallery {
          margin-top:2em;
          margin-bottom:3em;
          a {border:0;}
        }
      }
    }
  }
}

body.tpl-person-vonform.jan-roth-licht {
  div#main {
    div#content {
      div.works {
        li {
          a {
            img {
              border-radius: 0;
              box-shadow: 0px 30px 45px -15px rgba(0,0,0,0.25);
              &:hover {
                box-shadow: 0px 30px 65px -15px rgba(0,0,0,0.4);
              }
            }
          }
        }
      }
    }
  }

  .aagd-bounce-in {
    img {
      display: inline-block;
      vertical-align: middle;
      transform: translateY(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      backface-visibility: hidden;
      transition-duration: 0.25s;
    }
  }
  .aagd-bounce-in:hover, .aagd-bounce-in:focus, .aagd-bounce-in:active {
    img {
      // transform: translateZ(30px) rotateX(15deg) rotateY(2deg);
      transform: translateY(-8px);
      transition-timing-function: cubic-bezier(.87,-.41,.19,1.44);
    }
  }







}

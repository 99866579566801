/*********************************************************************
 * Header Area
 *
 */

#logo {
  background: url(../img/vonform.jpg) no-repeat; // png fallback image as proposed by http://css-tricks.com/using-svg/
  background-image: url(../img/vonform.svg), none; // if browser supports multiple backgrounds, it will probably be able to use the svg
  float: left;
  vertical-align: middle;
  margin-top:0; margin-left:0; padding:0; line-height:0; font-size:0;
  margin-bottom: 1em;
  margin-right: 30px;
  width: 312px;
  height: 90px;
  a {
    border:0;
  }
}

// NAVIGATION SEE: _navigation.scss

form.search {
  float: right;
  margin: 0;
  width: 30%;
}
  form.search input {
    margin: 0;
    padding: 0.25em 0.5em;
    border: 1px solid #ccc;
    width: 100%;
  }
  form.search button {
    display: none;
  }

.breadcrumbs {
  clear: both;
  padding-top: 1em;
}
  .breadcrumbs span:after {
    content: ">";
    color: #999;
    padding-left: 0.5em;
    padding-right: 0.25em;
  }
/*********************************************************************
 * Main content and sidebars
 *
 */

#main {
  border-top: 1px solid #eee;
  padding-top: 1em;
  margin-top: 1em;
  clear: both;
}

#content {
//  width: 85%;
}

#content, #rightbar {
  padding-bottom: 2em;
}

/*#leftbar {
  background-color: rgba(255,0,0,0.25);
}

#rightbar {
  background-color: rgba(0,255,0,0.25);
}

#content {
  background-color: rgba(0,0,255,0.25);
}
*/
body.has-leftbar #leftbar {
  width: 30%;
  float: left;
}

body.has-leftbar #content {
//  width: 70%;
//  float: left;
}

body.has-rightbar #content {
//  width: 65%;
//  float: left;
}

body.has-rightbar #rightbar {
  width: 35%;
  padding-left: 2%;
  float: left;
/*  font-size: 80%;*/
  line-height: 1.35em;
}



.nav {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}
  .nav .nav {
    padding-left: 1.5em;
/*    list-style: disc;*/
    list-style: none;
  }
  .nav li {
    margin: 1em 0;
  }

  .nav-tree li {
    margin-top: 0;
    margin-bottom: 0;
  }

  .nav a {
/*    font-weight: bold;*/
  }

  .nav-tree li a {
    color: #777;
  }

  .nav .current > a {
    color: #333;
  }

.align_left {
  /* for images placed in rich text editor */
  float: left;
  margin: 0 1em 0.5em 0;
  position: relative;
  top: 0.5em;
  max-width: 50%;
}

.align_right {
  /* for images placed in rich text editor */
  float: right;
  margin: 0 0 0.5em 1em;
  max-width: 50%;
}

.align_center {
  /* for images placed in rich text editor */
  display: block;
  margin: 1em auto;
  position: relative;
  top: 0.5em;
}

figure {
  display: table;
  width: 1px;
  margin: 1em 0;
}

figure img {
  display: table-row;
  margin-bottom: 0.5em;
}

figure figcaption {
  display: table-row;
  font-size: smaller;
  color: #777;
  line-height: 1.4em;
}




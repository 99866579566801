/*********************************************************************
 * Person
 *
 */

body.tpl-person {
  div#main {
    div#content {
      div.body {
        margin-bottom:3em;
//        @include susy-breakpoint(479px,6) { @include span(4 of 6); }
//        @include susy-breakpoint(1023px,6) { @include span(4 of 6); }
      }
      div.works {
        @include span(6 of 6);
        @include susy-breakpoint(479px,6) { @include span(4 of 4); }
        @include susy-breakpoint(1023px,6) { @include span(6 of 6); }

        li {
          @include gallery(3 of 6);
          @include susy-breakpoint(479px,6) { @include gallery(2 of 6); }
          @include susy-breakpoint(1023px,6) { @include gallery(1 of 6); }
          text-align: center;
          display: inline-block;
          vertical-align: top;
          margin:0 0 1em 0;
          padding:0;

          span.title {
            line-height: 1em;
            margin:0 0 10px 0;
            padding:0;
            display: inline-block;

          }


          a {
            border:0;
            img {
              border-radius: 50%;
              width:100%;
              max-width:195px;
              height:auto;
              max-height:195px;
              background-color: rgba(0,0,0,0.05);
              color: rgba(0,0,0,0.2);;
              display: inline-block;
              margin-bottom: 10px;
              box-shadow: 0px 30px 45px -15px rgba(0,0,0,0.25);
              &:hover {
                box-shadow: 0px 30px 65px -15px rgba(0,0,0,0.4);
              }
            }
          }
        }
      }
      div.gallery-page {
        margin-top:1em;
        margin-bottom:1em;
        div.text {
        }
        div.gallery {
          margin-top:2em;
          margin-bottom:3em;
          a {border:0;}
        }
      }
    }
  }
}